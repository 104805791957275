import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import HeroOther from "../components/hero-other";
import { checkPropertiesForNull } from "../utils/object";
import uuid4 from "uuid4";
import { SafeHtmlParser } from "../components/safe-html-parser";

const GolfProsPAge = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "golf-pros" }) {
        seoFieldGroups {
          localBusinessSchema
          metaDescription
          metaTitle
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        golfProsPageFieldGroups {
          golfProsBannerSection {
            heading
            subHeading
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            description
          }
          golfProsGoogleMapSection {
            heading
            map
            subHeading
          }
          golfProsWhereSection {
            heading
            subHeading
            items {
              description
              heading
            }
          }
          professionalSection1 {
            description
            heading
            leftDescription
            rightDescription
            subHeading
            cta {
              target
              title
              url
            }
          }
          professionalSection2 {
            description
            heading
            leftDescription
            rightDescription
            subHeading
            cta {
              target
              title
              url
            }
          }
        }
      }
      # allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
      #   nodes {
      #     localFile {
      #       childImageSharp {
      #         gatsbyImageData(
      #           formats: [AUTO, WEBP]
      #           quality: 100
      #           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
      #           layout: CONSTRAINED
      #           placeholder: BLURRED
      #         )
      #       }
      #     }
      #     caption

      #     permalink
      #     timestamp
      #   }
      # }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, golfProsPageFieldGroups },
    site,
  } = data;

  const {
    golfProsBannerSection,
    golfProsGoogleMapSection,
    golfProsWhereSection,
    professionalSection1,
    professionalSection2,
  } = golfProsPageFieldGroups;

  const siteUrl = site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Golf Pros",
        item: {
          url: `${siteUrl}/golf-pros`,
          id: `${siteUrl}/golf-pros`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/golf-pros`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {golfProsBannerSection &&
            !checkPropertiesForNull(golfProsBannerSection, ["heading"]) && (
              <HeroOther
                smallHeading={golfProsBannerSection?.subHeading}
                title={golfProsBannerSection?.heading}
                button1Text={null}
                button2Text={null}
                description={
                  <>
                    <SafeHtmlParser
                      htmlContent={golfProsBannerSection?.description}
                    />
                  </>
                }
                backgroundImage={
                  golfProsBannerSection.image.localFile.childImageSharp
                    .gatsbyImageData
                }
              />
            )}
          {professionalSection1 &&
            !checkPropertiesForNull(professionalSection1, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row className="pb-4 text-center">
                    <Col>
                      {" "}
                      {professionalSection1?.subHeading && (
                        <p className="text-uppercase text-secondary pb-2 mb-0 ssp-semibold">
                          {professionalSection1?.subHeading}
                        </p>
                      )}
                      <h2 className=" text-primary mb-3 ">
                        {professionalSection1?.heading}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="pb-4 g-5 gx-lg-7">
                    <Col lg={7}>
                      <div className="text-primary">
                        {professionalSection1?.leftDescription && (
                          <SafeHtmlParser
                            htmlContent={professionalSection1?.leftDescription}
                          />
                        )}
                      </div>
                    </Col>
                    <Col lg={5} className="text-primary">
                      <div className="text-primary">
                        {professionalSection1?.rightDescription && (
                          <SafeHtmlParser
                            htmlContent={professionalSection1?.rightDescription}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col className="text-center">
                      <div className="text-primary">
                        {professionalSection1.description && (
                          <SafeHtmlParser
                            htmlContent={professionalSection1.description}
                          />
                        )}
                      </div>

                      {professionalSection1.cta &&
                        professionalSection1.cta?.url && (
                          <Button
                            style={{ borderRadius: "0px" }}
                            variant="outline-primary"
                            href={professionalSection1.cta?.url}
                            className="py-3 w-100 w-md-auto mb-4 mb-md-0 mt-3 px-6 "
                          >
                            {professionalSection1.cta?.title}
                          </Button>
                        )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {professionalSection2 &&
            !checkPropertiesForNull(professionalSection2, ["heading"]) && (
              <section className="py-5 bg-primary py-lg-7">
                <Container>
                  <Row className="pb-4 text-center">
                    <Col>
                      {professionalSection2.subHeading && (
                        <p className="text-uppercase text-secondary pb-2 mb-0 ssp-semibold">
                          {professionalSection2.subHeading}
                        </p>
                      )}

                      <h2 className="mb-3 text-white">
                        {professionalSection2?.heading}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="g-5 gx-lg-7 align-items-center pb-4">
                    <Col lg={7}>
                      <div className="text-white">
                        {professionalSection2?.leftDescription && (
                          <SafeHtmlParser
                            htmlContent={professionalSection2?.leftDescription}
                          />
                        )}
                      </div>
                    </Col>
                    <Col lg={5} className="text-white">
                      {professionalSection2?.rightDescription && (
                        <SafeHtmlParser
                          htmlContent={professionalSection2?.rightDescription}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      {" "}
                      <div className="text-white">
                        {professionalSection2.description && (
                          <SafeHtmlParser
                            htmlContent={professionalSection2.description}
                          />
                        )}
                      </div>
                      {professionalSection2.cta &&
                        professionalSection2.cta?.url && (
                          <Button
                            style={{ borderRadius: "0px" }}
                            variant="outline-white"
                            href={professionalSection2.cta?.url}
                            className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-3 px-6 "
                          >
                            {professionalSection2.cta?.title}
                          </Button>
                        )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {golfProsWhereSection &&
            !checkPropertiesForNull(golfProsWhereSection, ["heading"]) && (
              <section className="py-5 bg-white py-lg-7">
                <Container>
                  <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                    <Col lg={8}>
                      {golfProsWhereSection?.subHeading && (
                        <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                          {golfProsWhereSection?.subHeading}
                        </p>
                      )}

                      <h2 className="line-heading-dark text-center mb-5 fs-1">
                        {golfProsWhereSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  {golfProsWhereSection?.items &&
                    golfProsWhereSection?.items.length > 0 && (
                      <Row>
                        <Col>
                          <Row className="g-5 h-lg-100">
                            {golfProsWhereSection?.items.map((item) => (
                              <Col
                                style={{ minHeight: "100%" }}
                                lg={4}
                                key={uuid4()}
                              >
                                <div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
                                  <h3 className="text-uppercase text-white fs-4 pb-3">
                                    {item.heading}
                                  </h3>
                                  {item.description && (
                                    <div className="text-white">
                                      <SafeHtmlParser
                                        htmlContent={item.description}
                                      />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    )}
                </Container>
              </section>
            )}
          {golfProsGoogleMapSection &&
            !checkPropertiesForNull(golfProsGoogleMapSection, ["heading"]) && (
              <section className="pb-5 pb-lg-7">
                <Container>
                  <Row className="">
                    <Col lg={8}>
                      {golfProsGoogleMapSection?.subHeading && (
                        <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                          {golfProsGoogleMapSection?.subHeading}
                        </p>
                      )}

                      <h2 className="  text-dark-green mb-5 fs-4">
                        {golfProsGoogleMapSection?.heading}
                      </h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <iframe
                        className="position-relative google-map"
                        src={golfProsGoogleMapSection?.map}
                        width="100%"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );
};

export default GolfProsPAge;
